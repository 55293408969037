/** *******************************************************************************************************************
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  
  Licensed under the Apache License, Version 2.0 (the "License").
  You may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  
      http://www.apache.org/licenses/LICENSE-2.0
  
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.                                                                              *
 ******************************************************************************************************************** */
import { FunctionComponent, useEffect, useState } from "react";
import Stack from "aws-northstar/layouts/Stack";
import Container from "aws-northstar/layouts/Container";
import ColumnLayout, { Column } from "aws-northstar/layouts/ColumnLayout";
import { Inline, StatusIndicator } from "aws-northstar";
import Alert from "aws-northstar/components/Alert";
import { url } from "inspector";

const Dashboard: FunctionComponent = () => {
  const [isSuccess, setIsSuccess] = useState<boolean | null>();
  useEffect(() => {
    fetch("https://api.hasiamfailedopenyet.com").then((response) => {
      if (response.status == 200) {
        console.log("success");
        setIsSuccess(true);
      } else {
        console.log("failure");
        setIsSuccess(false);
      }
    });
  }, []);

  if (isSuccess === true) {
    return (
      <Stack>
        <Container title="Has AWS IAM failed open globally?">
          Werner Vogels has pointed out that
          <a href="https://cacm.acm.org/magazines/2020/2/242334-everything-fails-all-the-time/fulltext">
            {" "}
            everything fails all the time
          </a>
          . <p></p>
          He has further pointed out that IAM processes{" "}
          <a href="https://siliconangle.com/2021/12/02/amazon-cto-werner-vogels-recalls-past-position-aws-cloud-future/">
            half a billion transactions per second
          </a>
          .<p></p>
          Combining the two is terrifying, so let's see if they've intersected
          yet:
          <p></p>
          <ColumnLayout>
            <Column key="column1">
              <Inline>
                <Alert type="success" header="IAM is working - for now...">
                  But remember, it's just a matter of time.
                </Alert>
              </Inline>
            </Column>
          </ColumnLayout>
        </Container>
      </Stack>
    );
  } else if (isSuccess === false) {
    return (
      <Stack>
        <Container title="Has AWS IAM failed open globally yet?">
          <ColumnLayout>
            <Column key="column1">
              <Inline>
                <Alert type="error" header="Time to take up farming!">
                  Woe betide all of us; AWS IAM has failed open globally and The
                  Cloud no longer exists as we knew it. Alternately, Lambda /
                  API Gateway / us-east-2 is having problems, which seems
                  likelier.
                </Alert>
              </Inline>
            </Column>
          </ColumnLayout>
        </Container>
      </Stack>
    );
  } else {
    return <Stack>// Reticulating AWS Splines...</Stack>;
  }
};

export default Dashboard;
